import { render, staticRenderFns } from "./usefulDentalCareSerbia.vue?vue&type=template&id=28634f7a&scoped=true"
import script from "./usefulDentalCareSerbia.vue?vue&type=script&lang=js"
export * from "./usefulDentalCareSerbia.vue?vue&type=script&lang=js"
import style0 from "./usefulDentalCareSerbia.vue?vue&type=style&index=0&id=28634f7a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28634f7a",
  null
  
)

/* custom blocks */
import block0 from "@/locales/useful_dental_care_serbia.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2Fuseful-dental-care%2FusefulDentalCareSerbia.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2Fuseful-dental-care%2FusefulDentalCareSerbia.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports